if ($('#capi-anos').length > 0) {
    var vmAnos = new Vue({
        el: '#capi-anos',
        data: {
            anos: servAnos,
            crear: {
                nombre: '', descripcion: '', cupos: ''
            },
            editar: {
                _method: 'PUT', id: '', nombre: '', descripcion: '', cupos: ''
            },
            eliminar: {
                _method: 'DELETE', id: '', nombre: ''
            },
            temporal: {}
        },
        methods: {
            crearAno: function () {
                var self = this;
                ejecutar_ajax('/capi/anos/almacenar', 'json', self.crear, $('#btnCrearAno'), function (ano) {
                    self.anos.unshift(ano);
                    limpiarObjecto(self.crear, ['estado']);
                    $('#crearAnoModal').modal('hide');
                    toastr.success('El año ' + ano.nombre + ' fue creado satisfactoriamente.', 'AÑOS');
                });
            },

            editarAno: function (ano) {
                rellenarObjeto(this.editar, ano, ['_method']);
                this.temporal = ano;
                this.$els.nombreAno.textContent = ano.nombre;
                $('#editarAnoModal').modal('show');
            },

            guardarAno: function () {
                var self = this;
                ejecutar_ajax('/capi/anos/actualizar', 'json', self.editar, $('#btnEditarAno'), function (ano) {
                    rellenarObjeto(self.temporal, ano, ['precios', 'total']);
                    $('#editarAnoModal').modal('hide');
                    toastr.info('Los cambios para el año ' + ano.nombre + ' fueron guardados satisfactoriamente.', 'AÑOS');
                });
            },

            confirmarEliminarAno: function (ano) {
                rellenarObjeto(this.eliminar, ano, ['_method']);
                this.temporal = ano;
                $('#eliminarAnoModal').modal('show');
            },

            eliminarAno: function () {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el año ' + self.eliminar.nombre + '?')) {
                    ejecutar_ajax('/capi/anos/eliminar', 'html', self.eliminar, $('#btnEliminarAno'), function () {
                        $('#eliminarAnoModal').modal('hide');
                        self.anos.$remove(self.temporal);
                        toastr.warning('El año ' + self.eliminar.nombre + ' fue eliminado satisfactoriamente.', 'AÑOS');
                    });
                }
            },

            mostrarPrecios: function (ano) {
                //this.temporal = ano;
                vmPrecios.precios = ano.precios;
                limpiarObjecto(vmPrecios.agregar);
                vmPrecios.$els.nombreAno.textContent = ano.nombre;
                vmPrecios.agregar.ano_id = ano.id;
                $('#preciosModal').modal('show');
            }
        }
    });

    $('#crearAnoModal').on('shown.bs.modal', function () {
        $('#crearNombre').focus();
    });

    $('#editarAnoModal').on('shown.bs.modal', function () {
        $('#editarNombre').focus();
    });

}
