if ($('#capi-modalidades').length > 0) {
    var vmModalidades = new Vue({
        el: '#capi-modalidades',
        data: {
            modalidades: servModalidades,
            crear: {
                nombre: '', edades: ''
            },
            editar: {
                _method: 'PUT', id: '', nombre: '', edades: ''
            },
            eliminar: {
                _method: 'DELETE', id: '', nombre: ''
            },
            temporal: {}
        },
        methods: {
            crearModalidad: function () {
                var self = this;
                ejecutar_ajax('/capi/modalidades/almacenar', 'json', self.crear, $('#btnCrearModalidad'), function (modalidad) {
                    self.modalidades.push(modalidad);
                    limpiarObjecto(self.crear);
                    $('#crearModalidadModal').modal('hide');
                    toastr.success('La modalidad ' + modalidad.nombre + ' fue creada satisfactoriamente.', 'MODALIDADES');
                });
            },

            editarModalidad: function (modalidad) {
                rellenarObjeto(this.editar, modalidad, ['_method']);
                this.temporal = modalidad;
                this.$els.nombreModalidad.textContent = modalidad.nombre;
                $('#editarModalidadModal').modal('show');
            },

            guardarModalidad: function () {
                var self = this;
                ejecutar_ajax('/capi/modalidades/actualizar', 'json', self.editar, $('#btnEditarModalidad'), function (modalidad) {
                    rellenarObjeto(self.temporal, modalidad);
                    $('#editarModalidadModal').modal('hide');
                    toastr.info('Los cambios para la modalidad ' + modalidad.nombre + ' fueron guardados satisfactoriamente.', 'MODALIDADES');
                });
            },

            eliminarModalidad: function (event, modalidad) {
                var self = this;
                rellenarObjeto(self.eliminar, modalidad, ['_method']);
                if (confirm('¿Seguro(a) qué desea eliminar la modalidad ' + self.eliminar.nombre + '?')) {
                    ejecutar_ajax('/capi/modalidades/eliminar', 'json', self.eliminar, $(event.currentTarget), function (modalidades) {
                        self.modalidades = modalidades;
                        toastr.warning('La modalidad ' + self.eliminar.nombre + ' fue eliminada satisfactoriamente.', 'MODALIDADES');
                    });
                }
            }
        }
    });

    $('#crearModalidadModal').on('shown.bs.modal', function () {
        $('#crearNombre').focus();
    });

    $('#editarModalidadModal').on('shown.bs.modal', function () {
        $('#editarNombre').focus();
    });

    $("#tblModalidades tbody").sortable({
        helper: fixHelperModified,
        stop: function(event, ui) {
            var i = 0;
            var values = [];
            ui.item.addClass('info');
            $(this).children('tr').each(function(index) {
                values[i] = $(this).data('modalidad-id');
                i++;
            });
            var data = { modalidades: values };
            ejecutar_ajax('/capi/modalidades/ordenar', 'json', data, null, function(modalidades) {
                ui.item.removeClass('info');
                vmModalidades.modalidades = modalidades;
                toastr.info('El orden de las modalidades fue actualizado satisfactoriamente.', 'MODALIDADES');
            });
        }
    }).disableSelection();

}
