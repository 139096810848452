if ($('#palabras').length > 0) {

	new Vue({
		el: '#palabras',

		data: {
            ministros: servMinistros,
            ministro: {
                nombre: '', palabras: []
            },
            palabra: {
                crear: { fecha: '', persona: '', ministro: '', tiempo: '', contenido: '' },
                editar: { _method: 'PUT', id: '', fecha: '', persona: '', ministro_id: '', tiempo: '', contenido: '' },
                temporal: {},
                eliminar: { _method: 'DELETE', id: '' }
            },
            busqueda: ''
		},

        methods: {
            cambioMinistro: function () {
                var self = this;
                $('#divMinistros').fadeOut('fast', function () {
                    self.busqueda = '';
                    self.ministro.nombre = '';
                    self.ministro.palabras = [];
                    limpiarObjecto(self.palabra.crear, ['ministro']);
                    if (self.palabra.crear.ministro !== '') {
                        self.ministros.forEach(function (ministro) {
                            if (parseInt(ministro.id) === parseInt(self.palabra.crear.ministro)) {
                                self.ministro.nombre = ministro.nombre;
                                self.ministro.palabras = ministro.palabras;
                            }
                        });
                    }
                    self.$nextTick(function () {
                        $('#divMinistros').fadeIn('slow');
                    });
                });
            },

            crearPalabra: function() {
                var self = this;
                self.palabra.crear.contenido = CKEDITOR.instances['txtCrearContenido'].getData();
                ejecutar_ajax('/ensenanzas/palabras/almacenar', 'json', self.palabra.crear, $('#btnCrearPalabra'), function(palabra) {
                    self.ministro.palabras.push(palabra);
                    limpiarObjecto(self.palabra.crear, ['ministro']);
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#crearPalabraModal').modal('hide');
                    toastr.success('Palabra profética ingresada satisfactoriamente.', 'PALABRAS');
                });
            },

            editarPalabra: function(palabra) {
                this.palabra.temporal = palabra;
                rellenarObjeto(this.palabra.editar, palabra, ['_method']);
                CKEDITOR.instances['txtEditarContenido'].setData(palabra.contenido);
                $('#editarPalabraModal').modal('show');
            },

            guardarPalabra: function() {
                var self = this;
                self.palabra.editar.contenido = CKEDITOR.instances['txtEditarContenido'].getData();
                ejecutar_ajax('/ensenanzas/palabras/actualizar', 'json', self.palabra.editar, $('#btnEditarPalabra'), function(palabra) {
                    rellenarObjeto(self.palabra.temporal, palabra);
                    $('#editarPalabraModal').modal('hide');
                    toastr.info('La palabra ha sido actualizada satisfactoriamente.', 'PALABRAS');
                });
            },

            mostrarPalabra: function(palabra) {
                this.$els.mostrarMinistro.textContent = this.ministro.nombre;
                this.$els.mostrarPersona.textContent = palabra.persona;
                this.$els.mostrarFecha.textContent = palabra.fecha;
                this.$els.mostrarTiempo.textContent = palabra.tiempo;
                this.$els.mostrarContenido.innerHTML = palabra.contenido;
                $('#mostrarPalabraModal').modal('show');
            },

            eliminarPalabra: function(event, palabra) {
                if (confirm("¿Seguro(a) qué desea eliminar la palabra dirigida a " + palabra.persona + "?")) {
                    var self = this;
                    self.palabra.eliminar.id = palabra.id;
                    ejecutar_ajax('/ensenanzas/palabras/eliminar', 'html', self.palabra.eliminar, $(event.currentTarget), function() {
                        self.ministro.palabras.$remove(palabra);
                        toastr.warning('La palabra ha sido eliminada.', 'PALABRAS');
                    });
                }
            }
        }
	});

    $('#crearPalabraModal').on('shown.bs.modal', function (event) { $('#txtCrearPersona').focus(); });
    $('#txtCrearFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtCrearContenido', { language: 'es' });

    $('#editarPalabraModal').on('shown.bs.modal', function (event) { $('#txtEditarPersona').focus(); });
    $('#txtEditarFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtEditarContenido', { language: 'es' });

}
