if ($('#ministros').length > 0) {

	new Vue({
		el: '#ministros',

		data: {
            busqueda: '',
            ministros: ministros,
            ministro: {
                crear: { nombre: '', pais: '', tipo: '' },
                editar: { _method: 'PUT', id: '', nombre: '', pais: '', tipo: '' },
                temporal: {},
                eliminar: { _method: 'DELETE', id: '' }
            }
		},

        methods: {
            crearMinistro: function() {
                var self = this;
                ejecutar_ajax('/ensenanzas/ministros/almacenar', 'json', self.ministro.crear, $('#btnCrearMinistro'), function(ministro) {
                    self.ministros.push(ministro);
                    limpiarObjecto(self.ministro.crear);
                    $('#crearMinistroModal').modal('hide');
                    toastr.success('MINISTRO CREADO');
                });
            },

            editarMinistro: function(ministro) {
                this.$els.editarMinistroNombre.textContent = ministro.nombre;
                this.ministro.temporal = ministro;
                rellenarObjeto(this.ministro.editar, ministro, ['_method']);
                $('#editarMinistroModal').modal('show');
            },

            guardarMinistro: function() {
                var self = this;
                ejecutar_ajax('/ensenanzas/ministros/actualizar', 'json', self.ministro.editar, $('#btnEditarMinistro'), function(ministro) {
                    rellenarObjeto(self.ministro.temporal, ministro);
                    $('#editarMinistroModal').modal('hide');
                    toastr.info('MINISTRO ACTUALIZADO');
                });
            },

            eliminarMinistro: function(ministro) {
                if (confirm("¿Seguro(a) qué desea eliminar el ministro '" + ministro.nombre + "'?")) {
                    var self = this;
                    self.ministro.eliminar.id = ministro.id;
                    ejecutar_ajax('/ensenanzas/ministros/eliminar', 'html', self.ministro.eliminar, $('button[data-eliminar="' + ministro.id + '"]'), function() {
                        self.ministros.$remove(ministro);
                        toastr.warning('MINISTRO ELIMINADO');
                    });
                }
            }
        }
	});

    $('#crearMinistroModal').on('shown.bs.modal', function (event) { $('#txtCrearNombre').focus(); });
    $('#editarMinistroModal').on('shown.bs.modal', function (event) { $('#txtEditarNombre').focus(); });

}
