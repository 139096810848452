if ($('#ever-estudiantes').length > 0) {

    var vmEverEstudiantes = new Vue({
        el: '#ever-estudiantes',

        data: {
            estudiantes: [],
            editar: {
                _method: 'PUT', id: '', nombre: '', identidad: '', genero: '', direccion: '', ciudad: '', pais: '', celular: '', tel_casa: '', correo: '', congregacion: '', estado: ''
            },
            temporal: {},
            eliminar: {
                _method: 'DELETE', id: ''
            }
        },

        created: function () {
            this.estudiantes = estudiantes;
        },

        methods: {
            editarEstudiante: function (estudiante) {
                rellenarObjeto(this.editar, estudiante, ['_method']);
                this.temporal = estudiante;
                this.$els.editarEstudianteTitulo.textContent = estudiante.nombre;
                $('#editarEstudianteModal').modal('show');
            },

            guardarEstudiante: function () {
                var self = this;
                ejecutar_ajax('/ever/estudiantes/actualizar', 'json', self.editar, $('#btnEditarEstudiante'), function (estudiante) {
                    rellenarObjeto(self.temporal, estudiante);
                    $('#editarEstudianteModal').modal('hide');
                    toastr.info('El estudiante ' + estudiante.nombre + ' fue actualizado satisfactoriamente.', 'EVER');
                });
            },

            eliminarEstudiante: function (event, estudiante) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar el estudiante "' + estudiante.nombre + '"?')) {
                    self.eliminar.id = estudiante.id;
                    ejecutar_ajax('/ever/estudiantes/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        toastr.warning('El estudiante ' + estudiante.nombre + ' ha sido eliminado.', 'EVER');
                        self.estudiantes.$remove(estudiante);
                    });
                }
            }
        }
    });

    $('#editarEstudianteModal').on('shown.bs.modal', function (event) { $('#txtEditarEstudianteNombre').focus(); });
}
