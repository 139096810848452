Vue.filter('fecha_hora', function (value) {
    return moment(value).format('DD/MM/YYYY hh:mm a');
});

Vue.filter('fecha', function (value) {
    return moment(value).format('DD/MM/YYYY');
});

Vue.filter('fecha_corta', function (value) {
    return moment(value).format('DD/MM');
});

Vue.filter('hora', function (value) {
    return moment(value).format('hh:mm a');
});

Vue.filter('edad', function (value) {
    return moment().diff(value, 'years');
});
