if ($('#inscripciones').length > 0) {

	new Vue({
		el: '#inscripciones',

		data: {
			ano: servAno,
			filtros: {
				nombre: '',
				tipo: '',
				sub_categoria: '',
				modalidad: '',
				transporte: '',
				asistencia: '',
				total: 0
			},
			orden: {
				campo: '',
				direccion: 1
			},
			inscripcion: {
				crear: {
					ano_id: '', nombre: '', edad: '', funcion: '', congregacion: '', direccion: '', ciudad: '', pais: '', tel_casa: '', tel_oficina: '', celular: '', email: '', modalidad_id: '', sub_categoria: '', tipo: '', transporte: '', fecha_hora_llegada: '', fecha_hora_salida: '', linea_aerea: '', linea_aerea_salida: '', numero_vuelo_llegada: '', numero_vuelo_salida: '', familiar_inmediato: '', parentesco: '', asistencia: 0
				},
				editar: {
					_method: 'PUT', id: '', nombre: '', edad: '', funcion: '', congregacion: '', direccion: '', ciudad: '', pais: '', tel_casa: '', tel_oficina: '', celular: '', email: '', modalidad_id: '', sub_categoria: '', tipo: '', transporte: '', fecha_hora_llegada: '', fecha_hora_salida: '', linea_aerea: '', linea_aerea_salida: '', numero_vuelo_llegada: '', numero_vuelo_salida: '', familiar_inmediato: '', parentesco: ''
				},
				eliminar: {
					_method: 'DELETE', id: ''
				},
				mostrar: {
					nombre: '', edad: '', funcion: '', congregacion: '', direccion: '', ciudad: '', pais: '', tel_casa: '', tel_oficina: '', celular: '', email: '', modalidad: '', sub_categoria: '', tipo: '', transporte: '', fecha_hora_llegada: '', fecha_hora_salida: '', linea_aerea: '', linea_aerea_salida: '', numero_vuelo_llegada: '', numero_vuelo_salida: '', familiar_inmediato: '', parentesco: '', hospedaje: '', asistencia: '', fecha_asistencia: '', attachment: '',
				},
				temporal: {}
			}
		},

		filters: {
			count: function (arr) {
				this.filtros.total = arr.length;
				return arr
			}
		},

		methods: {
			totales: function (tipo) {
				var total = 0;
				this.ano.inscripciones.forEach(function (inscripcion) {
					if (inscripcion.tipo == tipo) total++;
				});
				return total;
			},

			removerFiltros: function () {
				this.filtros.tipo = '';
				this.filtros.modalidad = '';
				this.filtros.transporte = '';
				this.filtros.asistencia = '';
				this.filtros.sub_categoria = '';
			},

			ordenarPor: function(event, campo) {
				if (this.orden.campo === campo) {
					this.orden.direccion = (this.orden.direccion * -1);
				} else {
					this.orden.campo = campo;
					this.orden.direccion = 1;
					$('#tblInscipciones thead tr').find('span').html('');
				}
				$(event.target).find('span').html(parseInt(this.orden.direccion) === 1 ? ' <i class="fa fa-arrow-up"></i>' : ' <i class="fa fa-arrow-down"></i>');
			},

			limpiarBusqueda: function () {
				this.filtros.nombre = '';
				this.$nextTick(function () {
					$('#filtroNombre').focus();
				});
			},

			totalInscripcionesPorFiltro: function () {
				return $('#tblInscipciones tbody tr').length;
			},

			crearInscripcion: function() {
				var self = this;
				self.inscripcion.crear.ano_id = self.ano.id;
				ejecutar_ajax('/capi/inscripciones/almacenar', 'json', self.inscripcion.crear, $('#btnCrearInscripcion'), function(inscripcion) {
					self.ano.inscripciones.push(inscripcion);
					limpiarObjecto(self.inscripcion.crear, ['asistencia']);
					self.inscripcion.crear.asistencia = 0;
					self.$nextTick(function() {
						$('#crearInscripcionModal').modal('hide');
						if (parseInt(inscripcion.asistencia) === 1) {
							window.open('/capi/inscripciones/imprimir/' + inscripcion.id);
						}
					});
				});
			},

			editarInscripcion: function(inscripcion) {
				this.inscripcion.temporal = inscripcion;
				rellenarObjeto(this.inscripcion.editar, inscripcion, ['_method']);
				this.$els.editarInscripcionNombre.textContent = inscripcion.nombre;
				$('#editarInscripcionModal').modal('show');
			},

			guardarInscripcion: function() {
				var self = this;
				ejecutar_ajax('/capi/inscripciones/actualizar', 'json', self.inscripcion.editar, $('#btnEditarInscripcion'), function(inscripcion) {
					rellenarObjeto(self.inscripcion.temporal, inscripcion);
					self.$nextTick(function() {
						toastr.info('La inscripción fue actualizada satisfactoriamente.', 'CAPI');
						$('#editarInscripcionModal').modal('hide');
					});
				});
			},

			eliminarInscripcion: function(event, inscripcion) {
				var self = this;
				if (confirm('¿Seguro(a) qué desea eliminar la inscripción seleccionada?')) {
					self.inscripcion.eliminar.id = inscripcion.id;
					ejecutar_ajax('/capi/inscripciones/eliminar', 'html', self.inscripcion.eliminar, $(event.currentTarget), function() {
						self.ano.inscripciones.$remove(inscripcion);
						toastr.warning('La inscripción fue eliminada satisfactoriamente.', 'CAPI');
					});
				}
			},

			mostrarInscripcion: function(inscripcion) {
				rellenarObjeto(this.inscripcion.mostrar, inscripcion, ['modalidad']);
				this.inscripcion.mostrar.modalidad = inscripcion.modalidad.nombre;
				this.$nextTick(function() {
					$('#mostrarInscripcionModal').modal('show');
				});
			},

			exportarInscripciones: function() {
				var tipo = this.filtros.tipo !== '' ? this.filtros.tipo : 'no_aplica';
				var sub_categoria = this.filtros.sub_categoria !== '' ? this.filtros.sub_categoria : 'no_aplica';
				var modalidad = this.filtros.modalidad !== '' ? this.filtros.modalidad : 'no_aplica';
				var transporte = this.filtros.transporte !== '' ? this.filtros.transporte : 'no_aplica';
				var asistencia = this.filtros.asistencia !== '' ? this.filtros.asistencia : 'no_aplica';
				window.location = '/capi/inscripciones/exportar/excel/' + this.ano.id + '/' + tipo + '/' + modalidad + '/' + transporte + '/' + asistencia + '/' + sub_categoria;
			},

			actualizarHospedaje: function (inscripcion) {
				ejecutar_ajax('/capi/inscripciones/actualizar_hospedaje', 'html', inscripcion, null, function () {
					toastr.info('El hospedaje de la inscripción fue actualizado satisfactoriamente.', 'CAPI');
				});
			},

			actualizarAsistencia: function (inscripcion) {
				ejecutar_ajax('/capi/inscripciones/actualizar_asistencia', 'json', inscripcion, null, function (nueva_inscripcion) {
					rellenarObjeto(inscripcion, nueva_inscripcion, ['modalidad']);
					if (parseInt(nueva_inscripcion.asistencia) === 0) {
						toastr.info('La asistencia fue actualizada satisfactoriamente.', 'CAPI');
					} else {
						window.open('/capi/inscripciones/imprimir/' + nueva_inscripcion.id);
					}
				});
			},

			actualizarBeca: function (inscripcion) {
				ejecutar_ajax('/capi/inscripciones/actualizar_beca', 'html', inscripcion, null, function () {
					toastr.info('La beca de la inscripción fue actualizada satisfactoriamente.', 'CAPI');
				});
			}
		}
	});

	$('#filtrosModal').on('shown.bs.modal', function (event) {
		$('#filtroAsistencia').focus();
	});

	$('#exportarModal').on('shown.bs.modal', function (event) {
		$('#exportarTipo').focus();
	});

	$('#crearInscripcionModal').on('shown.bs.modal', function (event) {
		$('#crearNombre').focus();
	});
	$('#crearFechaHoraLlegada').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY hh:mm a' });
	$('#crearFechaHoraSalida').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY hh:mm a' });

	$('#editarInscripcionModal').on('shown.bs.modal', function (event) {
		$('#editarNombre').focus();
	});
	$('#editarFechaHoraLlegada').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY hh:mm a' });
	$('#editarFechaHoraSalida').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY hh:mm a' });

}
