if ($('#banners').length > 0) {

	vmBanners = new Vue({
		el: '#banners',

		data: {
			banners: banners,
            crear: { titulo: '', tipo: '', enlace: '', contenido: '' },
            editar: { id: '', titulo: '', tipo: '', enlace: '', contenido: '', imagen: '', estado: '' }
		},

        methods: {
            crearBanner: function () {
                var self = this;

                var formData = new FormData();
                formData.append('titulo', self.crear.titulo);
                formData.append('tipo', self.crear.tipo);
                formData.append('enlace', self.crear.enlace);
                formData.append('contenido', CKEDITOR.instances['txtCrearContenido'].getData());
                formData.append('imagen', $('#txtCrearImagen')[0].files[0]);

                ejecutar_ajax_archivo('/website/banners/almacenar', 'json', formData, $('#btnCrearBanner'), function (resultado) {
                    self.banners.push(resultado);
                    $('#crearBannerModal').modal('hide');
                    limpiarObjecto(self.crear);
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#txtCrearImagen').val('');
                    toastr.info('BANNER CREADO');
                });
            },

            eliminarBanner: function (banner) {
                if (confirm('¿Seguro(a) qué desea eliminar el banner "' + banner.titulo + '"?')) {
                    var self = this;
                    ejecutar_ajax('/website/banners/eliminar', 'html', banner, $('button[data-eliminar="' + banner.id + '"]'), function (result) {
                        self.banners.$remove(banner);
                        toastr.warning('BANNER ELIMINADO');
                    });
                }
            },

            mostrarBanner: function(banner)
            {
                for (var propiedad in this.editar) {
                    if (this.editar.hasOwnProperty(propiedad)) {
                        this.editar[propiedad] = banner[propiedad];
                    }
                }
                this.$els.edicionBannerTitulo.textContent = this.editar.titulo;
                CKEDITOR.instances['txtEditarContenido'].setData(this.editar.contenido);
                $('#editarBannerModal').modal('show');
            },

            editarBanner: function()
            {
                var self = this;

                var formData = new FormData();
                formData.append('id', self.editar.id);
                formData.append('titulo', self.editar.titulo);
                formData.append('tipo', self.editar.tipo);
                formData.append('enlace', self.editar.enlace);
                formData.append('contenido', CKEDITOR.instances['txtEditarContenido'].getData());
                formData.append('estado', self.editar.estado);
                formData.append('imagen', $('#txtEditarImagen')[0].files[0]);

                ejecutar_ajax_archivo('/website/banners/actualizar', 'json', formData, $('#btnEditarBanner'), function (resultado) {
                    self.banners.forEach(function(banner, index) {
                        if (parseInt(resultado.id) === parseInt(banner.id)) {
                            self.banners.$set(index, resultado);
                        }
                    });
                    $('#editarBannerModal').modal('hide');
                    CKEDITOR.instances['txtEditarContenido'].setData('');
                    $('#txtEditarImagen').val('');
                    toastr.info('BANNER EDITADO');
                });
            }
        }
	});

    $('#tblBanners tbody').sortable({
        helper: fixHelperModified,
        stop: function(event, ui) {
            ui.item.addClass('warning');
            var values = {};
            var i = 0;
            $("#tblBanners tbody tr").each(function (index) {
                values[i] = $(this).data('banner-id');
                i++;
            });
            var data = {
                elements: values
            };
            ejecutar_ajax('/website/banners/ordenar', 'json', data, null, function(banners) {
                ui.item.removeClass('warning');
                vmBanners.banners = banners;
                toastr.info('El orden de los banners fue actualizado satisfactoriamente.', 'BANNERS');
            });
        }
    }).disableSelection();

    $('#crearBannerModal').on('shown.bs.modal', function (event) { $('#txtCrearTitulo').focus(); });
    CKEDITOR.replace('txtCrearContenido', { language: 'es' });

    $('#editarBannerModal').on('shown.bs.modal', function (event) { $('#txtEditarTitulo').focus(); });
    CKEDITOR.replace('txtEditarContenido', { language: 'es' });
}
