if ($('#reportes').length > 0) {

    var vmReportes = new Vue({
        el: '#reportes',

        data: {
            ano: { id: '', nombre: '', clases: [] },
            anos: [],
            estudiantes: [],
            busqueda: '',
            filtro: 0
        },

        created: function () {
            if (!$.isEmptyObject(ano)) {
                rellenarObjeto(this.ano, ano);
                this.estudiantes = estudiantes;
                this.ano.clases.forEach(function (clase) {
                    chartLabels.push(moment(clase.fecha).format('MMM-DD'));
                    chartData.push(clase.total);
                    chartBgColor.push('rgba(75, 192, 192, 0.5)');
                    chartBdColor.push('rgba(75, 192, 192, 1)');
                });
            }
        },

        computed: {
            totalAsistencia: function () {
                var total = 0;
                this.ano.clases.forEach(function (clase) {
                    total += parseInt(clase.total);
                });
                return total;
            }
        },

        methods: {
            filtroAsistencia: function (estudiantes) {
                var self = this;
                switch  (parseInt(this.filtro)) {
                    case 0:
                        return estudiantes;
                        break;
                    case 1:
                        return estudiantes.filter(function (estudiante) {
                            return self.totalNota(estudiante.clases.nota, estudiante.clases.extra) > 69;
                        });
                        break;
                    case 2:
                        return estudiantes.filter(function (estudiante) {
                            return self.totalNota(estudiante.clases.nota, estudiante.clases.extra) < 70;
                        });
                        break;
                }
            },

            mostrarAnos: function (event) {
                var self = this;
                ejecutar_ajax('/ever/anos/obtener', 'json', { id: Math.random() }, $(event.currentTarget), function (anos) {
                    self.anos = anos;
                    $('#anosModal').modal('show');
                });
            },

            cargarAno: function (event, ano) {
                var self = this;
                ejecutar_ajax('/ever/reportes/cargar', 'json', { id: ano.id }, $(event.currentTarget), function (resultado) {
                    chartData.length = 0;
                    chartLabels.length = 0;
                    chartBgColor.length = 0;
                    chartBdColor.length = 0;
                    myChart.update();
                    rellenarObjeto(self.ano, resultado.ano);
                    self.estudiantes = resultado.estudiantes;
                    self.ano.clases.forEach(function (clase) {
                        chartLabels.push(moment(clase.fecha).format('MMM-DD'));
                        chartData.push(clase.total);
                        chartBgColor.push('rgba(75, 192, 192, 0.5)');
                        chartBdColor.push('rgba(75, 192, 192, 1)');
                    });
                    $('#anosModal').modal('hide');
                });
            },

            estiloAsistencia: function (asistencia) {
                if (asistencia != null) {
                    if (asistencia.tipo == 'Normal' && asistencia.tardanza) return 'text-muted';
                    if (asistencia.tipo == 'Normal' && !asistencia.tardanza) return 'text-success';
                    if (asistencia.tipo == 'Excusa') return 'text-warning';
                }
                return '';
            },

            estiloSombra: function (asistencia) {
                if (asistencia != null) {
                    if (asistencia.tipo == 'Normal' && asistencia.tardanza) return '#b4bcc2';
                    if (asistencia.tipo == 'Normal' && !asistencia.tardanza) return '#18bc9c';
                    if (asistencia.tipo == 'Excusa') return '#f39c12';
                }
                return '';
            },

            estiloNota: function (nota, extra) {
                extra = extra != null ? extra.nota : 0;
                total = parseInt(nota) + parseInt(extra);
                if (total < 70) {
                    return 'danger';
                } else {
                    return 'success';
                }
            },

            totalNota: function (nota, extra) {
                extra = extra != null ? extra.nota : 0;
                return parseInt(nota) + parseInt(extra);
            },

            puntosExtra: function (estudiante) {
                var puntos = prompt('Ingrese cantidad de puntos extra:', '');
                if (puntos != null) {
                    var datos = {
                        ano_id: ano.id,
                        estudiante_id: estudiante.id,
                        nota: puntos
                    }
                    ejecutar_ajax('/ever/reportes/extra', 'json', datos, null, function (extra) {
                        estudiante.clases.extra = extra;
                        toastr.success('Puntos extra asignados al estudiante ' + estudiante.nombre + '.', 'EVER');
                    });
                }
            },

            exportarAprobados: function () {
                window.open('/ever/reportes/aprobados/' + this.ano.id, '_blank');
            },

            exportarCorreos: function () {
                window.open('/ever/reportes/correos/' + this.ano.id, '_blank');
            },

            formatoHora: function (hora) {
                return moment('2016-11-18 ' + hora).format('hh:mm a');
            }
        }
    });

    var ctx = document.getElementById("myChart").getContext("2d");
    var myChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: chartLabels,
            datasets: [{
                label: 'Asistencia',
                data: chartData,
                borderWidth: 1,
                backgroundColor: chartBgColor,
                borderColor: chartBdColor
            }]
        },
        options: {
            scales: {
                yAxes: [{
                    ticks: { beginAtZero:true }
                }]
            }
        }
    });

    $('#anosModal').on('hidden.bs.modal', function (event) { myChart.update(); });

}
