if ($('#eventos').length > 0) {

	new Vue({
		el: '#eventos',

		data: {
            busqueda: '',
            eventos: eventos,
            evento: {
                crear: { nombre: '' },
                editar: { _method: 'PUT', id: '', nombre: '' },
                temporal: {},
                eliminar: { _method: 'DELETE', id: '' }
            }
		},

        methods: {
            crearEvento: function() {
                var self = this;
                ejecutar_ajax('/ensenanzas/eventos/almacenar', 'json', self.evento.crear, $('#btnCrearEvento'), function(evento) {
                    self.eventos.unshift(evento);
                    limpiarObjecto(self.evento.crear);
                    $('#crearEventoModal').modal('hide');
                    toastr.success('EVENTO CREADO');
                });
            },

            editarEvento: function(evento) {
                this.$els.editarEventoNombre.textContent = evento.nombre;
                this.evento.temporal = evento;
                rellenarObjeto(this.evento.editar, evento, ['_method']);
                $('#editarEventoModal').modal('show');
            },

            guardarEvento: function() {
                var self = this;
                ejecutar_ajax('/ensenanzas/eventos/actualizar', 'json', self.evento.editar, $('#btnEditarEvento'), function(evento) {
                    rellenarObjeto(self.evento.temporal, evento);
                    $('#editarEventoModal').modal('hide');
                    toastr.info('EVENTO ACTUALIZADO');
                });
            },

            eliminarEvento: function(evento) {
                if (confirm("¿Seguro(a) qué desea eliminar el evento '" + evento.nombre + "'?")) {
                    var self = this;
                    self.evento.eliminar.id = evento.id;
                    ejecutar_ajax('/ensenanzas/eventos/eliminar', 'html', self.evento.eliminar, $('button[data-eliminar="' + evento.id + '"]'), function() {
                        self.eventos.$remove(evento);
                        toastr.warning('EVENTO ELIMINADO');
                    });
                }
            }
        }
	});

    $('#crearEventoModal').on('shown.bs.modal', function (event) { $('#txtCrearNombre').focus(); });
    $('#editarEventoModal').on('shown.bs.modal', function (event) { $('#txtEditarNombre').focus(); });

}
