if ($('#modulos').length > 0) {
    new Vue({
        el: '#modulos',

        data: {
            modulos: modulos,
            crear: {
                modulo: { nombre: '', descripcion: '' },
                leccion: { modulo_id: '', tipo: 1, nombre: '', contenido: '', video: '', audio: '' },
                pregunta: { modulo_id: '', leccion_id: '', contenido: '' },
                respuesta: { pregunta_id: '', contenido: '' }
            },
            editar: {
                modulo: { _method: 'PUT', id: '', nombre: '', descripcion: '' },
                leccion: { _method: 'PUT', id: '', modulo_id: '', tipo: '', nombre: '', contenido: '', video: '', audio: '' },
                pregunta: { _method: 'PUT', id: '', contenido: '', indiceModulo: '', indiceLeccion: '', indicePregunta: '' }
            },
            respuestas: []
        },

        methods: {
            // ------------ MODULOS
                crearModulo: function () {
                    var self = this;
                    ejecutar_ajax('/epron/modulos/almacenar', 'json', self.crear.modulo, $('#btnCrearModulo'), function (resultado) {
                        self.modulos.push(resultado);
                        $('#crearModuloModal').modal('hide');
                        limpiarObjecto(self.crear.modulo);
                        toastr.info('MÓDULO CREADO');
                    });
                },

                editarModulo: function(modulo) {
                    this.editar.modulo.id = modulo.id;
                    this.editar.modulo.nombre = modulo.name;
                    this.editar.modulo.descripcion = modulo.description;
                    $('#editarModuloModal').modal('show');
                },

                guardarModulo: function() {
                    var self = this;
                    ejecutar_ajax('/epron/modulos/actualizar', 'json', self.editar.modulo, $('#btnEditarModulo'), function (resultado) {
                        var indice = null;
                        self.modulos.forEach(function(modulo, index) {
                            if (parseInt(modulo.id) === parseInt(resultado.id)) {
                                indice = index;
                            }
                        });
                        self.modulos[indice].name = resultado.name;
                        self.modulos[indice].description = resultado.description;
                        self.modulos[indice].updated_at = resultado.updated_at;
                        $('#editarModuloModal').modal('hide');
                        toastr.info('MÓDULO ACTUALIZADO');
                    });
                },

                eliminarModulo: function(modulo) {
                    if (confirm("¿Seguro(a) qué desea eliminar el módulo seleccionado?")) {
                        var self = this;
                        modulo._method = 'DELETE';
                        ejecutar_ajax('/epron/modulos/eliminar', 'html', modulo, $('button[data-modulo-eliminar="' + modulo.id + '"]'), function (result) {
                            self.modulos.$remove(modulo);
                            $('#moduloTabs a:first').tab('show');
                            toastr.warning('MÓDULO ELIMINADO');
                        });
                    }
                },

            // ------------ LECCIONES
                nuevaLeccion: function(modulo) {
                    if (modulo.lecciones.length === 17) {
                        alert('Solo se permite un máximo de 17 lecciones por módulo.');
                    } else {
                        this.crear.leccion.modulo_id = modulo.id;
                        $('#crearLeccionModal').modal('show');
                    }
                },

                crearLeccion: function() {
                    var self = this;
                    self.crear.leccion.contenido = CKEDITOR.instances['crearLeccionContenido'].getData();
                    ejecutar_ajax('/epron/lecciones/almacenar', 'json', self.crear.leccion, $('#btnCrearLeccion'), function (resultado) {
                        var indice = null;
                        self.modulos.forEach(function(modulo, index) {
                            if (parseInt(modulo.id) === parseInt(self.crear.leccion.modulo_id)) {
                                indice = index;
                            }
                        });
                        self.modulos[indice].lecciones.push(resultado);
                        $('#crearLeccionModal').modal('hide');
                        limpiarObjecto(self.crear.leccion);
                        self.crear.leccion.tipo = 1;
                        CKEDITOR.instances['crearLeccionContenido'].setData('');
                        toastr.info('LECCIÓN CREADA');
                        $('html, body').animate({ scrollTop: $(document).height() }, 'slow');
                    });
                },

                editarLeccion: function(leccion) {
                    this.editar.leccion.id = leccion.id;
                    this.editar.leccion.modulo_id = leccion.module_id;
                    this.editar.leccion.tipo = leccion.type;
                    this.editar.leccion.nombre = leccion.name;
                    this.editar.leccion.contenido = leccion.content;
                    this.editar.leccion.video = leccion.video;
                    this.editar.leccion.audio = leccion.audio;
                    CKEDITOR.instances['editarLeccionContenido'].setData(this.editar.leccion.contenido);
                    $('#editarLeccionModal').modal('show');
                },

                guardarLeccion: function() {
                    var self = this;
                    self.editar.leccion.contenido = CKEDITOR.instances['editarLeccionContenido'].getData();
                    ejecutar_ajax('/epron/lecciones/actualizar', 'json', self.editar.leccion, $('#btnEditarLeccion'), function (resultado) {
                        self.modulos.forEach(function(modulo) {
                            if (parseInt(modulo.id) === parseInt(resultado.module_id)) {
                                modulo.lecciones.forEach(function(leccion) {
                                    if (parseInt(leccion.id) === parseInt(resultado.id)) {
                                        leccion.name = resultado.name;
                                        leccion.type = resultado.type;
                                        leccion.content = resultado.content;
                                        leccion.video = resultado.video;
                                        leccion.audio = resultado.audio;
                                        leccion.updated_at = resultado.updated_at;
                                        CKEDITOR.instances['editarLeccionContenido'].setData('');
                                        $('#editarLeccionModal').modal('hide');
                                        toastr.info('LECCIÓN ACTUALIZADA');
                                    }
                                });
                            }
                        });
                    });
                },

                eliminarLeccion: function(modulo, leccion) {
                    if (confirm("¿Seguro(a) qué desea eliminar la lección seleccionada?")) {
                        var self = this;
                        leccion._method = 'DELETE';
                        ejecutar_ajax('/epron/lecciones/eliminar', 'html', leccion, $('button[data-leccion-eliminar="' + leccion.id + '"]'), function (resultado) {
                            modulo.lecciones.$remove(leccion);
                            modulo.lecciones.forEach(function(leccion, index) {
                                leccion.number = (index + 1)
                            });
                            toastr.warning('LECCIÓN ELIMINADA');
                        });
                    }
                },

            // ------------ PREGUNTAS
                nuevaPregunta: function(leccion)
                {
                    if (leccion.preguntas.length === 4 && parseInt(leccion.type) === 1) {
                        alert('Solo se permite un máximo de 4 preguntas por lección.');
                    } else if (leccion.preguntas.length === 10 && parseInt(leccion.type) === 2) {
                        alert('Solo se permite un máximo de 10 preguntas por autoevaluación.');
                    } else {
                        this.crear.pregunta.leccion_id = leccion.id;
                        this.crear.pregunta.modulo_id = leccion.module_id;
                        $('#crearPreguntaModal').modal('show');
                    }
                },

                crearPregunta: function()
                {
                    var self = this;
                    ejecutar_ajax('/epron/preguntas/almacenar', 'json', self.crear.pregunta, $('#btnCrearPregunta'), function (resultado) {
                        self.modulos.forEach(function(modulo) {
                            if (parseInt(modulo.id) === parseInt(self.crear.pregunta.modulo_id)) {
                                modulo.lecciones.forEach(function(leccion) {
                                    if (parseInt(leccion.id) === parseInt(self.crear.pregunta.leccion_id)) {
                                        leccion.preguntas.push(resultado);
                                    }
                                });
                            }
                        });

                        $('#crearPreguntaModal').modal('hide');
                        limpiarObjecto(self.crear.pregunta);
                        toastr.info('PREGUNTA CREADA');
                    });
                },

                editarPregunta: function(indiceModulo, indiceLeccion, indicePregunta, pregunta)
                {
                    this.editar.pregunta.id = pregunta.id;
                    this.editar.pregunta.contenido = pregunta.content;
                    this.editar.pregunta.indiceModulo = indiceModulo;
                    this.editar.pregunta.indiceLeccion = indiceLeccion;
                    this.editar.pregunta.indicePregunta = indicePregunta;
                    $('#editarPreguntaModal').modal('show');
                },

                guardarPregunta: function()
                {
                    var self = this;
                    ejecutar_ajax('/epron/preguntas/actualizar', 'json', self.editar.pregunta, $('#btnEditarPregunta'), function(resultado) {
                        var pregunta = self.modulos[self.editar.pregunta.indiceModulo].lecciones[self.editar.pregunta.indiceLeccion].preguntas[self.editar.pregunta.indicePregunta];
                        pregunta.content = resultado.content;
                        pregunta.updated_at = resultado.updated_at;
                        $('#editarPreguntaModal').modal('hide');
                        toastr.info('PREGUNTA ACTUALIZADA');
                    });
                },

                eliminarPregunta: function (leccion, pregunta)
                {
                    if (confirm("¿Seguro(a) qué desea eliminar la pregunta seleccionada?")) {
                        var self = this;
                        pregunta._method = 'DELETE';
                        ejecutar_ajax('/epron/preguntas/eliminar', 'html', pregunta, $('button[data-pregunta-eliminar="' + pregunta.id + '"]'), function (resultado) {
                            leccion.preguntas.$remove(pregunta);
                            leccion.preguntas.forEach(function(pregunta, index) {
                                pregunta.number = (index + 1)
                            });
                            toastr.warning('PREGUNTA ELIMINADA');
                        });
                    }
                },

            // ------------ RESPUESTAS
                mostrarRespuestas: function(pregunta_id, respuestas)
                {
                    this.respuestas = respuestas;
                    this.crear.respuesta.pregunta_id = pregunta_id;
                    this.crear.respuesta.contenido = ''
                    $('#respuestasModal').modal('show');
                },

                crearRespuesta: function()
                {
                    var self = this;
                    if (self.crear.respuesta.contenido != '') {
                        ejecutar_ajax('/epron/respuestas/almacenar', 'json', self.crear.respuesta, $('#btnCrearRespuesta'), function(resultado) {
                            self.respuestas.push(resultado);
                            self.crear.respuesta.contenido = '';
                            $('#crearRespuestaContenido').focus();
                        });
                    } else {
                        $('#crearRespuestaContenido').focus();
                    }
                },

                editarRespuesta: function(respuestaCorrecta)
                {
                    var self = this;
                    self.respuestas.forEach(function(respuesta){
                        if (parseInt(respuesta.id) !== parseInt(respuestaCorrecta.id)) {
                            respuesta.status = 0;
                        }
                    });

                    respuestaCorrecta._method = 'PUT';
                    ejecutar_ajax('/epron/respuestas/actualizar', 'html', respuestaCorrecta, null, function() {
                        toastr.info('RESPUESTA ACTUALIZADA');
                    });
                },

                eliminarRespuesta: function(respuesta)
                {
                    if (confirm("¿Seguro(a) qué desea eliminar la respuesta seleccionada?")) {
                        var self = this;
                        respuesta._method = 'DELETE';
                        ejecutar_ajax('/epron/respuestas/eliminar', 'html', respuesta, $('button[data-respuesta-eliminar="' + respuesta.id + '"]'), function (resultado) {
                            self.respuestas.$remove(respuesta);
                            self.respuestas.forEach(function(respuesta, index) {
                                respuesta.number = (index + 1)
                            });
                            toastr.warning('RESPUESTA ELIMINADA');
                        });
                    }
                }
        }
    });

    $('#crearModuloModal').on('shown.bs.modal', function (event) { $('#crearModuloNombre').focus(); });
    $('#editarModuloModal').on('shown.bs.modal', function (event) { $('#editarModuloNombre').focus(); });

    $('#crearLeccionModal').on('shown.bs.modal', function (event) { $('#crearLeccionNombre').focus(); });
    CKEDITOR.replace('crearLeccionContenido', { language: 'es' });

    $('#editarLeccionModal').on('shown.bs.modal', function (event) { $('#editarLeccionNombre').focus(); });
    CKEDITOR.replace('editarLeccionContenido', { language: 'es' });

    $('#crearPreguntaModal').on('shown.bs.modal', function (event) { $('#crearPreguntaContenido').focus(); });
    $('#editarPreguntaModal').on('shown.bs.modal', function (event) { $('#editarPreguntaContenido').focus(); });

    $('#respuestasModal').on('shown.bs.modal', function (event) { $('#crearRespuestaContenido').focus(); });
}

if ($('#estudiantes').length > 0) {
    new Vue({
        el: '#estudiantes',

        data: {
            buscar: '',
            estudiantes: estudiantes,
            estudiante: {
                crear: { nombre: '', email: '', ciudad: '', estado: '', pais: '', clave: '', numero: '', mes: '', ano: '', cvc: '' },
                editar: { _method: 'PUT', id: '', nombre: '', email: '', ciudad: '', estado: '', pais: '', numero: '', mes: '', ano: '', cvc: '' },
                niveles: []
            }
        },

        methods: {
            crearEstudiante: function()
            {
                var self = this;
                ejecutar_ajax('/epron/estudiantes/almacenar', 'json', self.estudiante.crear, $('#btnCrearEstudiante'), function (resultado) {
                    self.estudiantes.push(resultado);
                    $('#crearEstudianteModal').modal('hide');
                    limpiarObjecto(self.estudiante.crear);
                    toastr.info('ESTUDIANTE CREADO');
                });
            },

            editarEstudiante: function(estudiante)
            {
                this.estudiante.editar.id = estudiante.id;
                this.estudiante.editar.nombre = estudiante.name;
                this.estudiante.editar.email = estudiante.email;
                this.estudiante.editar.ciudad = estudiante.city;
                this.estudiante.editar.estado = estudiante.state;
                this.estudiante.editar.pais = estudiante.country;
                this.estudiante.editar.numero = estudiante.tarjeta.number;
                this.estudiante.editar.mes = estudiante.tarjeta.month;
                this.estudiante.editar.ano = estudiante.tarjeta.year;
                this.estudiante.editar.cvc = estudiante.tarjeta.cvc;
                this.$els.edicionEstudianteNombre.textContent = estudiante.name;
                $('#editarEstudianteModal').modal('show');
            },

            guardarEstudiante: function()
            {
                var self = this;
                ejecutar_ajax('/epron/estudiantes/actualizar', 'json', self.estudiante.editar, $('#btnEditarEstudiante'), function (resultado) {
                    self.estudiantes.every(function(estudiante, index) {
                        if (parseInt(estudiante.id) === parseInt(resultado.id)) {
                            self.estudiantes[index].name = resultado.name;
                            self.estudiantes[index].email = resultado.email;
                            self.estudiantes[index].city = resultado.city;
                            self.estudiantes[index].state = resultado.state;
                            self.estudiantes[index].country = resultado.country;
                            self.estudiantes[index].updated_at = resultado.updated_at;
                            self.estudiantes[index].tarjeta.number = resultado.tarjeta.number;
                            self.estudiantes[index].tarjeta.month = resultado.tarjeta.month;
                            self.estudiantes[index].tarjeta.year = resultado.tarjeta.year;
                            self.estudiantes[index].tarjeta.cvc = resultado.tarjeta.cvc;
                            return false;
                        }
                        return true;
                    });
                    $('#editarEstudianteModal').modal('hide');
                    toastr.info('ESTUDIANTE ACTUALIZADO');
                });
            },

            eliminarEstudiante: function(estudiante)
            {
                if (confirm("¿Seguro(a) qué desea eliminar el estudiante '" + estudiante.name + "'?")) {
                    var self = this;
                    estudiante._method = 'DELETE';
                    ejecutar_ajax('/epron/estudiantes/eliminar', 'html', estudiante, $('button[data-estudiante-eliminar="' + estudiante.id + '"]'), function (result) {
                        self.estudiantes.$remove(estudiante);
                        toastr.warning('ESTUDIANTE ELIMINADO');
                    });
                }
            },

            mostrarNiveles: function(estudiante)
            {
                this.estudiante.niveles = estudiante.niveles;
                $('#nivelesModal').modal('show');
            },

            actualizarEstadoNivel: function(nivel)
            {
                var self = this;
                nivel._method = 'PUT';
                ejecutar_ajax('/epron/nivel/actualizar', 'html', nivel, null, function (resultado) {
                    toastr.info('ESTADO ACTUALIZADO');
                });
            }
        }
    });

    $('#crearEstudianteModal').on('shown.bs.modal', function (event) { $('#crearEstudianteNombre').focus(); });
    $('#editarEstudianteModal').on('shown.bs.modal', function (event) { $('#editarEstudianteNombre').focus(); });
    $('#guardarTarjetaModal').on('shown.bs.modal', function (event) { $('#editarTarjetaNumero').focus(); });
}

if ($('#pagos').length > 0) {
    new Vue({
        el: '#pagos',

        data: {
            niveles: niveles
        },

        methods: {
            realizarPago: function(nivel)
            {
                ejecutar_ajax('/epron/pagos', 'html', nivel, $('button[data-btn="' + nivel.id + '"]'), function(resultado) {
                    toastr.info('PAGO REALIZADO');
                    nivel.status = 1;
                });
            },

            cancelarPago: function(nivel)
            {
                var self = this;
                if (confirm('¿Seguro(a) qué desea cancelar el pago seleccionado?')) {
                    ejecutar_ajax('/epron/cancelar', 'html', nivel, $('button[data-btn="' + nivel.id + '"]'), function(resultado) {
                        toastr.info('PAGO CANCELADO');
                        self.niveles.$remove(nivel);
                    });
                }
            }
        }
    });
}
