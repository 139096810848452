if ($('#transmision').length > 0) {

	$('#editarFechaHora').datetimepicker({
		locale: 'es',
		format: 'DD/MM/YYYY hh:mm a'
	});

	new Vue({
		el: '#transmision',

		data: {
			transmision: transmision,
			editar: { evento: '', fecha_hora: '', estado: '', youtube_id: '' }
		},

		methods: {
			mostrarDatos: function () {
				this.editar.evento = this.transmision.evento;
				this.editar.fecha_hora = this.transmision.fecha_hora;
				this.editar.youtube_id = this.transmision.youtube_id;
				this.editar.estado = this.transmision.estado;
				$('#transmisionModal').modal('show');
			},

			editarTransmision: function () {
				var self = this;
				ejecutar_ajax('/website/transmision/actualizar', 'json', self.editar, $('#btnEditarTransmision'), function (resultado) {
					self.transmision = resultado;
					$('#transmisionModal').modal('hide');
					toastr.info('DATOS ACTUALIZADOS');
				});
			}
		}
	});

	$('#transmisionModal').on('shown.bs.modal', function (event) {
		$('#editarEvento').focus();
	});

}
