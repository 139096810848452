if ($('#mensajes').length > 0) {

	var vmMensajes = new Vue({
		el: '#mensajes',

		data: {
            busqueda: { estado: 0, opcion: 1, texto: '', ano: 0 },
            eventos: [],
            ministros: [],
            categorias: [],
            mensajes: [],
            crear: { titulo: '', fecha: '', evento: '', ministro: '', categoria: '', sub_categoria: '', youtube: '', audiomack: '', transcripcion: '' },
            editar: { id: '', titulo: '', fecha: '', evento_id: '', ministro_id: '', categoria_id: '', sub_categoria: '', youtube: '', audiomack: '', pp: '', transcripcion: '' },
            mostrar: { titulo: '', fecha: '', ministro: '', evento: '', categoria: '', youtube: '', audiomack: '', pp: '', transcripcion: '' },
            temporal: {},
            eliminar: { _method: 'DELETE', id: '' }
		},

        ready: function () {
            this.busqueda.ano = ano;
            this.mensajes = mensajes;
            this.eventos = eventos;
            this.ministros = ministros;
            this.categorias = categorias;
        },

        methods: {
            limpiarBusqueda: function() {
                this.busqueda.texto = '';
                this.$nextTick(function() {
                    $('#filtroNombre').focus();
                });
            },

            realizarBusqueda: function () {
                var self = this;
                if (self.busqueda.texto != '') {
                    self.busqueda.estado = 1;
                    ejecutar_ajax('/ensenanzas/mensajes/busqueda/texto', 'json', self.busqueda, null, function (mensajes) {
                        self.mensajes = mensajes;
                        self.busqueda.estado = 0;
                        self.busqueda.opcion = 2;
                        toastr.info('Resultados: ' + mensajes.length, 'ENSEÑANZAS');
                    });
                }
            },

            recargarAno: function (event, operacion) {
                var self = this;
                self.busqueda.estado = 1;
                self.busqueda.ano += parseInt(operacion);
                ejecutar_ajax('/ensenanzas/mensajes/busqueda/recargar', 'json', self.busqueda, $(event.currentTarget), function (mensajes) {
                    self.mensajes = mensajes;
                    self.busqueda.estado = 0;
                    self.busqueda.opcion = 1;
                    toastr.info('Resultados: ' + mensajes.length, 'ENSEÑANZAS');
                });
            },

            obtenerSubCategorias: function(tipo) {
                var self = this;
                if (tipo == 'crear') {
                    $('#txtMensajeCrearSubCategoria').html('<option value="">-- CARGANDO --</option>');
                    if (self.crear.categoria != '') {
                        ejecutar_ajax('/ensenanzas/mensajes/subcategorias', 'html', { categoria_id: self.crear.categoria }, null, function(resultado) {
                            $('#txtMensajeCrearSubCategoria').html(resultado);
                        });
                    } else {
                        $('#txtMensajeCrearSubCategoria').html('<option value="">-- Sub Categoría --</option>');
                    }
                } else {
                    $('#txtMensajeEditarSubCategoria').html('<option value="">-- CARGANDO --</option>');
                    if (self.editar.categoria_id != '') {
                        ejecutar_ajax('/ensenanzas/mensajes/subcategorias', 'html', { categoria_id: self.editar.categoria_id }, null, function(resultado) {
                            $('#txtMensajeEditarSubCategoria').html(resultado);
                        });
                    } else {
                        $('#txtMensajeEditarSubCategoria').html('<option value="">-- Sub Categoría --</option>');
                    }
                }
            },

            crearMensaje: function() {
                var self = this;

                var formData = new FormData();
                formData.append('titulo', self.crear.titulo);
                formData.append('fecha', self.crear.fecha);
                formData.append('evento', self.crear.evento);
                formData.append('ministro', self.crear.ministro);
                formData.append('categoria', self.crear.categoria);
                formData.append('sub_categoria', self.crear.sub_categoria);
                formData.append('youtube', self.crear.youtube);
                formData.append('audiomack', self.crear.audiomack);
                formData.append('transcripcion', CKEDITOR.instances['txtMensajeCrearTranscripcion'].getData());
                formData.append('pp', $('#txtMensajeCrearPp')[0].files[0]);

                ejecutar_ajax_archivo('/ensenanzas/mensajes/almacenar', 'json', formData, $('#btnMensajeCrear'), function (resultado) {
                    self.mensajes.unshift(resultado);
                    $('#crearMensajeModal').modal('hide');
                    limpiarObjecto(self.crear);
                    CKEDITOR.instances['txtMensajeCrearTranscripcion'].setData('');
                    $('#txtMensajeCrearPp').val('');
                    toastr.info('El mensaje ' + resultado.titulo + ' fue creado satisfactoriamente.', 'ENSEÑANZAS');
                });
            },

            mostrarMensaje: function(mensaje)
            {
                this.mostrar.titulo = mensaje.titulo;
                this.mostrar.youtube = mensaje.youtube;
                this.mostrar.audiomack = mensaje.audiomack;
                this.mostrar.fecha = mensaje.fecha;
                this.mostrar.ministro = mensaje.ministro.nombre;
                this.mostrar.evento = mensaje.evento.nombre;
                this.mostrar.categoria = mensaje.categoria.nombre;
                this.mostrar.pp = mensaje.pp;
                this.mostrar.transcripcion = mensaje.transcripcion;
                this.$els.mostrarTitulo.textContent = mensaje.titulo;
                $('#mostrarMensajeModal').modal('show');
            },

            editarMensaje: function(event, mensaje)
            {
                var self = this;
                self.editar.sub_categoria = '';
                ejecutar_ajax('/ensenanzas/mensajes/subcategorias', 'html', { categoria_id: mensaje.categoria_id }, $(event.currentTarget), function(resultado) {
                    $('#txtMensajeEditarSubCategoria').html(resultado);
                    rellenarObjeto(self.editar, mensaje);
                    self.temporal = mensaje;
                    CKEDITOR.instances['txtMensajeEditarTranscripcion'].setData(self.editar.transcripcion);
                    self.$els.edicionTitulo.textContent = mensaje.titulo;
                    $('#editarMensajeModal').modal('show');
                });
            },

            guardarMensaje: function ()
            {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('id', self.editar.id);
                formData.append('titulo', self.editar.titulo);
                formData.append('fecha', self.editar.fecha);
                formData.append('evento', self.editar.evento_id);
                formData.append('ministro', self.editar.ministro_id);
                formData.append('categoria', self.editar.categoria_id);
                formData.append('sub_categoria', self.editar.sub_categoria);
                formData.append('youtube', self.editar.youtube);
                formData.append('audiomack', self.editar.audiomack);
                formData.append('transcripcion', CKEDITOR.instances['txtMensajeEditarTranscripcion'].getData());
                formData.append('pp', $('#txtMensajeEditarPp')[0].files[0]);

                ejecutar_ajax_archivo('/ensenanzas/mensajes/actualizar', 'json', formData, $('#btnMensajeEditar'), function (mensaje) {
                    rellenarObjeto(self.temporal, mensaje);
                    toastr.info('El mensaje ' + mensaje.titulo + ' fue actualizado satisfactoriamente.', 'ENSEÑANZAS');
                    $('#editarMensajeModal').modal('hide');
                    $('#txtMensajeEditarPp').val('');
                    CKEDITOR.instances['txtMensajeEditarTranscripcion'].setData('');
                });
            },

            eliminarMensaje: function (event, mensaje) {
                if (confirm("¿Seguro(a) qué desea eliminar el mensaje '" + mensaje.titulo + "'?")) {
                    var self = this;
                    self.eliminar.id = mensaje.id;
                    ejecutar_ajax('/ensenanzas/mensajes/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        self.mensajes.$remove(mensaje);
                        toastr.warning('El mensaje ' + mensaje.titulo + ' ha sido eliminado', 'ENSEÑANZAS');
                    });
                }
            }
        }
	});

    $('#mostrarMensajeModal').on('hidden.bs.modal', function (event) {
        vmMensajes.mostrar.youtube = '';
        vmMensajes.mostrar.audiomack = '';
    });

    $('#crearMensajeModal').on('shown.bs.modal', function (event) { $('#txtMensajeCrearTitulo').focus(); });
    $('#txtMensajeCrearFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtMensajeCrearTranscripcion', { language: 'es' });

    $('#editarMensajeModal').on('shown.bs.modal', function (event) { $('#txtMensajeEditarTitulo').focus(); });
    $('#txtMensajeEditarFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtMensajeEditarTranscripcion', { language: 'es' });

}
