if ($('#libros').length > 0) {

	new Vue({
		el: '#libros',

		data: {
            busqueda: '',
            libros: libros,
            crear: { title: '', summary: '', type: '', local_price: '', foreign_price: '' },
            editar: { id: '', title: '', summary: '', type: '', local_price: '', foreign_price: '', image: '' },
            temporal: {},
            eliminar: { _method: 'DELETE', id: '' }
		},

        methods: {
            crearLibro: function() {
                var self = this;

                var formData = new FormData();
                formData.append('title', self.crear.title);
                formData.append('summary', self.crear.summary);
                formData.append('type', self.crear.type);
                formData.append('local_price', self.crear.local_price);
                formData.append('foreign_price', self.crear.foreign_price);
                formData.append('image', $('#txtCrearImagen')[0].files[0]);

                ejecutar_ajax_archivo('/promuna/libros/almacenar', 'json', formData, $('#btnCrearLibro'), function(libro) {
                    self.libros.push(libro);
                    limpiarObjecto(self.crear);
                    $('#txtCrearImagen').val('');
                    $('#crearLibroModal').modal('hide');
                    toastr.success('LIBRO CREADO');
                });
            },

            editarLibro: function(libro) {
                this.$els.editarLibroNombre.textContent = libro.title;
                this.temporal = libro;
                rellenarObjeto(this.editar, libro, ['_method']);
                $('#editarLibroModal').modal('show');
            },

            guardarLibro: function() {
                var self = this;

                var formData = new FormData();
                formData.append('_method', 'PUT');
                formData.append('id', self.editar.id);
                formData.append('title', self.editar.title);
                formData.append('summary', self.editar.summary);
                formData.append('type', self.editar.type);
                formData.append('local_price', self.editar.local_price);
                formData.append('foreign_price', self.editar.foreign_price);
                formData.append('image', $('#txtEditarImagen')[0].files[0]);

                ejecutar_ajax_archivo('/promuna/libros/actualizar', 'json', formData, $('#btnEditarLibro'), function(libro) {
                    rellenarObjeto(self.temporal, libro);
                    $('#editarLibroModal').modal('hide');
                    $('#txtEditarImagen').val('');
                    toastr.info('LIBRO ACTUALIZADO');
                });
            },

            eliminarLibro: function(event, libro) {
                if (confirm("¿Seguro(a) qué desea eliminar el libro '" + libro.title + "'?")) {
                    var self = this;
                    self.eliminar.id = libro.id;
                    ejecutar_ajax('/promuna/libros/eliminar', 'html', self.eliminar, $(event.currentTarget), function() {
                        self.libros.$remove(libro);
                        toastr.warning('LIBRO ELIMINADO');
                    });
                }
            }
        }
	});

    $('#crearLibroModal').on('shown.bs.modal', function (event) { $('#txtCrearTitulo').focus(); });
    $('#editarLibroModal').on('shown.bs.modal', function (event) { $('#txtEditarTitulo').focus(); });

}
