if ($('#asistencia').length > 0) {

    var vmAsistencia = new Vue({
        el: '#asistencia',

        data: {
            ano: {
                seleccionado: { id: '', nombre: '', clases: [] },
                crear: { nombre: '' }
            },
            anos: [],
            lista_clases: '',
            clase: {
                cargando: false,
                seleccionada: { id: '', ano_id: '', fecha: '', hora: '', limite: '' },
                crear: { ano_id: '', fecha: '', hora: '', limite: '' },
                asistencias: [],
                editar: { _method: 'PUT', id: '', fecha: '', hora: '', limite: '' }
            },
            busqueda: {
                texto: '', resultados: []
            },
            estudiante: {
                crear: { clase_id: '', nombre: '', identidad: '', genero: '', direccion: '', ciudad: '', pais: 'Honduras', celular: '', tel_casa: '', correo: '', congregacion: '' }
            }
        },

        created: function () {
            if (!$.isEmptyObject(ano)) {
                rellenarObjeto(this.ano.seleccionado, ano);
            }
        },

        methods: {
            // ANOS
            crearAno: function () {
                var self = this;
                ejecutar_ajax('/ever/anos/almacenar', 'json', self.ano.crear, $('#btnCrearAno'), function (ano) {
                    rellenarObjeto(self.ano.seleccionado, ano);
                    $('#crearAnoModal').modal('hide');
                    limpiarObjecto(self.ano.crear);
                    self.lista_clases = '';
                    toastr.info('El año ' + ano.nombre + ' ha sido creado satisfactoriamente.', 'EVER');
                });
            },

            mostrarAnos: function (event) {
                var self = this;
                ejecutar_ajax('/ever/anos/obtener', 'json', { id: Math.random() }, $(event.currentTarget), function (anos) {
                    self.anos = anos;
                    $('#anosModal').modal('show');
                });
            },

            cargarAno: function (event, ano) {
                var self = this;
                ejecutar_ajax('/ever/anos/cargar', 'json', { id: ano.id }, $(event.currentTarget), function (ano) {
                    rellenarObjeto(self.ano.seleccionado, ano);
                    self.lista_clases = '';
                    $('#anosModal').modal('hide');
                    toastr.info('El año ' + ano.nombre + ' ha sido cargado satisfactoriamente.', 'EVER');
                });
            },

            // CLASES
            crearClase: function () {
                var self = this;
                self.clase.crear.ano_id = self.ano.seleccionado.id;
                ejecutar_ajax('/ever/clases/almacenar', 'json', self.clase.crear, $('#btnCrearClase'), function (clase) {
                    self.ano.seleccionado.clases.push(clase);
                    $('#crearClaseModal').modal('hide');
                    limpiarObjecto(self.clase.crear);
                    self.lista_clases = clase.id;
                    toastr.info('La clase ' + clase.fecha + ' ha sido creada satisfactoriamente.', 'EVER');
                    self.$nextTick(function () {
                        self.obtenerAsistencia(true);
                    });
                });
            },

            editarClase: function () {
                rellenarObjeto(this.clase.editar, this.clase.seleccionada, ['_method']);
                $('#editarClaseModal').modal('show');
            },

            guardarClase: function () {
                var self = this;
                ejecutar_ajax('/ever/clases/actualizar', 'json', self.clase.editar, $('#btnEditarClase'), function (clase) {
                    rellenarObjeto(self.clase.seleccionada, clase);
                    self.ano.seleccionado.clases.forEach(function (anoClase) {
                        if (parseInt(anoClase.id) === parseInt(clase.id)) {
                            rellenarObjeto(anoClase, clase);
                        }
                    });
                    $('#editarClaseModal').modal('hide');
                    toastr.info('La clase ' + clase.fecha + ' ha sido actualizada satisfactoriamente.', 'EVER');
                });
            },

            eliminarClase: function (event) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea eliminar la clase del día ' + self.clase.seleccionada.fecha + '? Esto también eliminará la asistencia correspondiente.')) {
                    var datos = { _method: 'DELETE', id: self.clase.seleccionada.id };
                    ejecutar_ajax('/ever/clases/eliminar', 'html', datos, $(event.currentTarget), function () {
                        self.ano.seleccionado.clases.forEach(function (anoClase) {
                            if (parseInt(anoClase.id) === parseInt(self.clase.seleccionada.id)) {
                                self.ano.seleccionado.clases.$remove(anoClase);
                            }
                        });
                        toastr.warning('La clase del día ' + self.clase.seleccionada.fecha + ' ha sido eliminada.', 'EVER');
                        self.lista_clases = '';
                    });
                }
            },

            // ASISTENCIA
            obtenerAsistencia: function (carga) {
                var self = this;
                if (self.lista_clases != '') {
                    if (carga) self.clase.cargando = true;
                    ejecutar_ajax('/ever/asistencia', 'json', { clase_id: self.lista_clases }, null, function (clase) {
                        rellenarObjeto(self.clase.seleccionada, clase);
                        self.clase.asistencias = clase.asistencias;
                        self.estudiante.crear.clase_id = clase.id;
                        self.$nextTick(function () {
                            toastr.info('Asistencia cargada satisfactoriamente.', 'EVER');
                            if (carga) {
                                self.clase.cargando = false;
                                self.$nextTick(function () {
                                    $('#txtBusqueda').focus();
                                });
                            }
                        });
                    });
                }
            },

            contarExcusas: function (asistencias) {
                var total = 0;
                asistencias.forEach(function (asistencia) {
                    if (asistencia.tipo == 'Excusa') total += 1;
                });
                return total;
            },

            contarTardanzas: function (limite, asistencias) {
                var total = 0;
                asistencias.forEach(function (asistencia) {
                    if (Date.parse('2017-05-22 ' + asistencia.hora) > Date.parse('2017-05-22 ' + limite)) total += 1;
                });
                return total;
            },

            realizarBusqueda: function () {
                var self = this;
                if (self.busqueda.texto != '') {
                    ejecutar_ajax('/ever/asistencia/busqueda', 'json', { texto: self.busqueda.texto }, $('#btnBusqueda'), function (resultados) {
                        self.busqueda.resultados = resultados;
                        $('#resultadosModal').modal('show');
                    });
                } else {
                    $('#txtBusqueda').focus();
                }
            },

            marcarAsistencia: function (event, estudiante, tipo) {
                var self = this;
                var datos = {
                    clase_id: self.clase.seleccionada.id,
                    estudiante_id: estudiante.id,
                    tipo: tipo
                };
                ejecutar_ajax('/ever/asistencia/marcar', 'json', datos, $(event.currentTarget), function (asistencia) {
                    self.clase.asistencias.push(asistencia);
                    self.busqueda.texto = '';
                    $('#resultadosModal').modal('hide');
                    toastr.success('La asistencia del estudiante ' + estudiante.nombre + ' fue marcada satisfactoriamente.', 'EVER');
                });
            },

            removerAsistencia: function (event, asistencia) {
                var self = this;
                if (confirm('¿Seguro(a) qué desea remover la asistencia del estudiante ' + asistencia.estudiante.nombre + '?')) {
                    var datos = { _method: 'DELETE', id: asistencia.id };
                    ejecutar_ajax('/ever/asistencia/remover', 'html', datos, $(event.currentTarget), function () {
                        self.clase.asistencias.$remove(asistencia);
                        toastr.warning('La asistencia del estudiante ' + asistencia.estudiante.nombre + ' ha sido removida.', 'EVER');
                    });
                }
            },

            cambiarHora: function (asistencia) {
                var hora = prompt('Ingrese la nueva hora:', asistencia.hora);
                if (hora != null) {
                    var datos = {
                        _method: 'PUT',
                        id: asistencia.id,
                        hora: hora
                    }
                    ejecutar_ajax('/ever/asistencia/hora', 'json', datos, null, function (nuevaAsistencia) {
                        asistencia.hora = nuevaAsistencia.hora;
                        toastr.success('La asistencia del estudiante ' + asistencia.estudiante.nombre + ' fue actualizada satisfactoriamente.', 'EVER');
                    });
                }
            },

            formatoHora: function (hora) {
                return moment('2016-11-18 ' + hora).format('hh:mm a');
            },

            // ESTUDIANTES
            crearEstudiante: function () {
                var self = this;
                ejecutar_ajax('/ever/estudiantes/almacenar', 'json', self.estudiante.crear, $('#btnCrearEstudiante'), function (asistencia) {
                    self.clase.asistencias.push(asistencia);
                    $('#crearEstudianteModal').modal('hide');
                    limpiarObjecto(self.estudiante.crear, ['clase_id', 'pais']);
                    toastr.success('El estudiante ' + asistencia.estudiante.nombre + ' fue creado satisfactoriamente.', 'EVER');
                });
            }
        }
    });

    setInterval(function () {
        if (vmAsistencia.lista_clases != '') {
            ejecutar_ajax('/ever/asistencia', 'json', { clase_id: vmAsistencia.lista_clases }, null, function (clase) {
                vmAsistencia.clase.asistencias = clase.asistencias;
            });
        }
    }, 20000);

    $('#crearAnoModal').on('shown.bs.modal', function (event) { $('#txtCrearAnoNombre').focus(); });
    $('#crearClaseModal').on('shown.bs.modal', function (event) { $('#txtCrearClaseFecha').focus(); });
    $('#editarClaseModal').on('shown.bs.modal', function (event) { $('#txtEditarClaseFecha').focus(); });
    $('#crearEstudianteModal').on('shown.bs.modal', function (event) { $('#txtCrearEstudianteNombre').focus(); });
    $('#resultadosModal').on('hidden.bs.modal', function (event) { $('#txtBusqueda').focus(); });
}
