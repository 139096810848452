if ($('#capi-precios').length > 0) {

    var vmPrecios = new Vue({
        el: '#capi-precios',
        data: {
            precios: [],
            agregar: {
                ano_id: '', modalidad_id: '', locales: '', extranjeros: ''
            },
            eliminar: {
                _method: 'DELETE', id: ''
            }
        },
        methods: {
            agregarPrecio: function () {
                var self = this;
                ejecutar_ajax('/capi/precios/almacenar', 'json', self.agregar, $('#btnAgregarPrecio'), function (precio) {
                    self.precios.push(precio);
                    limpiarObjecto(self.agregar, ['ano_id']);
                    toastr.success('Precio agregado satisfactoriamente.', 'AÑOS');
                    $('#preciosModalidad').focus();
                });
            },

            eliminarPrecio: function (event, precio) {
                if (confirm('¿Seguro(a) qué desea eliminar el precio seleccionado?')) {
                    var self = this;
                    self.eliminar.id = precio.id;
                    ejecutar_ajax('/capi/precios/eliminar', 'html', self.eliminar, $(event.currentTarget), function () {
                        self.precios.$remove(precio);
                        toastr.warning('Precio eliminado satisfactoriamente.', 'AÑOS');
                        $('#preciosModalidad').focus();
                    });
                }
            }
        }
    });

    $('#preciosModal').on('shown.bs.modal', function () {
        $('#preciosModalidad').focus();
    });

}
