if ($('#usuarios').length > 0) {

	new Vue({
		el: '#usuarios',

		data: {
			usuarios: usuarios,
			buscar: '',
			crear: { nombre: '', tipo: 'Editor', email: '', password: '' },
			editar: { id: '', nombre: '', tipo: '', email: '', password: '', estado: '' },
			permisos: { usuario_id: '', nombre: '', ensenanzas: '', palabras: '', website: '', promuna: '', epron: '', ever: '', capi: '' }
		},

		methods: {
			nuevoUsuario: function () {
				$('#crearUsuarioModal').modal('show');
			},

			crearUsuario: function () {
				var self = this;
				ejecutar_ajax('/configuracion/usuarios/almacenar', 'json', self.crear, $('#btnCrearUsuario'), function (result) {
					self.usuarios.push(result);
					$('#crearUsuarioModal').modal('hide');
					limpiarObjecto(self.crear);
					self.crear.tipo = 'Editor';
					toastr.info('USUARIO CREADO');
					$('html, body').animate({ scrollTop: $(document).height() }, 'slow');
				});
			},

			mostrarUsuario: function (usuario) {
				for (var propiedad in this.editar) {
					if (this.editar.hasOwnProperty(propiedad)) {
						this.editar[propiedad] = usuario[propiedad];
					}
				}
				this.editar.password = '';
				$('#editarUsuarioModal').modal('show');
			},

			editarUsuario: function () {
				var self = this;
				ejecutar_ajax('/configuracion/usuarios/actualizar', 'json', self.editar, $('#btnEditarUsuario'), function (resultado) {
					var indice = null;
					self.usuarios.forEach(function(usuario, index) {
						if (parseInt(resultado.id) === parseInt(usuario.id)) {
							indice = index;
						}
					});
					self.usuarios.$set(indice, resultado);
					$('#editarUsuarioModal').modal('hide');
					toastr.info('USUARIO EDITADO');
				});
			},

			eliminarUsuario: function (usuario) {
				if (confirm('¿Seguro(a) qué desea eliminar el usuario seleccionado?')) {
					var self = this;
					ejecutar_ajax('/configuracion/usuarios/eliminar', 'html', usuario, $('button[data-eliminar="' + usuario.id + '"]'), function (result) {
						self.usuarios.$remove(usuario);
						toastr.warning('USUARIO ELIMINADO');
					});
				}
			},

			mostrarPermisos: function (usuario) {
				if (usuario.tipo == 'Administrador') {
					alert('Solo se pueden modificar permisos a usuarios de tipo Editor.');
				} else {
					for (var propiedad in this.permisos) {
						if (this.permisos.hasOwnProperty(propiedad)) {
							if (propiedad !== 'nombre') this.permisos[propiedad] = usuario.permisos[propiedad];
						}
					}
					this.permisos.nombre = usuario.nombre;
					$('#permisosUsuarioModal').modal('show');
				}
			},

			guardarPermisos: function () {
				var self = this;
				ejecutar_ajax('/configuracion/usuarios/permisos', 'json', self.permisos, $('#btnPermisosUsuario'), function (resultado) {
					var indice = null;
					self.usuarios.forEach(function(usuario, index) {
						if (parseInt(usuario.id) === parseInt(resultado.id)) {
							indice = index;
						}
					});
					self.usuarios.$set(indice, resultado);
					$('#permisosUsuarioModal').modal('hide');
					toastr.info('PERMISOS ACTUALIZADOS');
				});
			}
		}
	});

	$('#crearUsuarioModal').on('shown.bs.modal', function (event) {
		$('#crearNombre').focus();
	});

	$('#editarUsuarioModal').on('shown.bs.modal', function (event) {
		$('#editarNombre').focus();
	});

}
