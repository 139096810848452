// PASSWORD CHANGE
    $('#passwordModal').on('shown.bs.modal', function () {
        $('#contrasena_actual').focus();
    });

    new Vue({
        el: '#cambio-contrasena',

        data: {
            password: {
                actual: '',
                nueva: '',
                confirme: ''
            }
        },

        methods: {
            guardarContrasena: function()
            {
                var self = this;
                ejecutar_ajax('/contrasena', 'html', self.password, $('#btnPassword'), function(resultado)
                {
                    $('#passwordModal').modal('hide');
                    self.password.actual = '';
                    self.password.nueva = '';
                    self.password.confirme = '';
                });
            }
        }
    });
