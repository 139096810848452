if ($('#esperanza').length > 0) {

	new Vue({
		el: '#esperanza',

		data: {
			articulos: articulos,
			busqueda: { texto: '', ano: 0, mes: 0 },
            crear: { titulo: '', fecha: '', contenido: '' },
            editar: { id: '', titulo: '', fecha: '', contenido: '', imagen: '' }
		},

        methods: {
            realizarBusqueda: function () {
                var self = this;
                ejecutar_ajax('/website/esperanza/busqueda', 'json', self.busqueda, $('#btnBusqueda'), function (resultado) {
                    self.articulos = resultado;
                    $('#busquedaModal').modal('hide');
                    toastr.info('RESULTADOS: ' + resultado.length);
                });
            },

            crearArticulo: function () {
                var self = this;

                var formData = new FormData();
                formData.append('titulo', self.crear.titulo);
                formData.append('fecha', self.crear.fecha);
                formData.append('contenido', CKEDITOR.instances['txtCrearContenido'].getData());
                formData.append('imagen', $('#txtCrearImagen')[0].files[0]);

                ejecutar_ajax_archivo('/website/esperanza/almacenar', 'json', formData, $('#btnCrear'), function (resultado) {
                    self.articulos.unshift(resultado);
                    $('#crearArticuloModal').modal('hide');
                    limpiarObjecto(self.crear);
                    CKEDITOR.instances['txtCrearContenido'].setData('');
                    $('#txtCrearImagen').val('');
                    toastr.info('ARTÍCULO CREADO');
                });
            },

            mostrarArticulo: function (articulo)
            {
                for (var propiedad in this.editar) {
                    if (this.editar.hasOwnProperty(propiedad)) {
                        this.editar[propiedad] = articulo[propiedad];
                    }
                }
                CKEDITOR.instances['txtEditarContenido'].setData(this.editar.contenido);
                $('#editarArticuloModal').modal('show');
            },

            editarArticulo: function ()
            {
                var self = this;

                var formData = new FormData();
                formData.append('id', self.editar.id);
                formData.append('titulo', self.editar.titulo);
                formData.append('fecha', self.editar.fecha);
                formData.append('contenido', CKEDITOR.instances['txtEditarContenido'].getData());
                formData.append('imagen', $('#txtEditarImagen')[0].files[0]);

                ejecutar_ajax_archivo('/website/esperanza/actualizar', 'json', formData, $('#btnEditar'), function (resultado) {
                    var indice = null;
                    self.articulos.forEach(function(articulo, index) {
                        if (parseInt(resultado.id) === parseInt(articulo.id)) {
                            indice = index;
                        }
                    });
                    self.articulos.$set(indice, resultado);
                    $('#editarArticuloModal').modal('hide');
                    CKEDITOR.instances['txtEditarContenido'].setData('');
                    $('#txtEditarImagen').val('');
                    toastr.info('ARTÍCULO EDITADO');
                });
            },

            eliminarArticulo: function (articulo) {
                if (confirm('¿Seguro(a) qué desea eliminar el artículo seleccionado?')) {
                    var self = this;
                    ejecutar_ajax('/website/esperanza/eliminar', 'html', articulo, $('button[data-eliminar="' + articulo.id + '"]'), function (result) {
                        self.articulos.$remove(articulo);
                        toastr.warning('ARTÍCULO ELIMINADO');
                    });
                }
            }
        }
	});

    $('#busquedaModal').on('shown.bs.modal', function (event) { $('#txtBuscar').focus(); });

    $('#crearArticuloModal').on('shown.bs.modal', function (event) { $('#txtCrearTitulo').focus(); });
    $('#txtCrearFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtCrearContenido', { language: 'es' });

    $('#editarArticuloModal').on('shown.bs.modal', function (event) { $('#txtEditarTitulo').focus(); });
    $('#txtEditarFecha').datetimepicker({ locale: 'es', format: 'DD/MM/YYYY' });
    CKEDITOR.replace('txtEditarContenido', { language: 'es' });

}
